<template>
  <div class="app-container">
    <div class="page-title">
      <h2>{{ $t('recolte.recoltes') }}</h2>
    </div>
    <el-card v-if="checkRole(['admin'])" class="box-card filter-card just-for-admin">
      <div slot="header" class="clearfix">
        <span>{{ $t('general.filter') }}</span>
        <el-button style="float: right; padding: 3px 0" type="text" @click="initSearch">{{ $t('general.init') }}</el-button>
      </div>
      <div class="filter-container">
        <el-button class="mr-15 filter-item" type="success" icon="el-icon-search" @click="handleSearchSac">
          Rechercher un sac
        </el-button>
        <el-radio label="corbeille" class="danger-radio" border>{{ $t('general.corbeille') }}</el-radio>
      </div>
      <hr>
      <div style="display:flex; flex-direction:row">
        <el-select
          v-model="campagneFilter"
          placeholder="Campagne"
          style="margin-right:10px; width:200px"
          @change="filterByCampagne(campagneFilter)">
          <el-option
            v-for="(item, index) in campagnes"
            :key="index"
            :label="item.periode"
            :value="index"
          />
        </el-select>
      </div>
    </el-card>

    <el-card class="box-card">
      <div class="filter-container">
        <el-input v-model="query.keyword" :placeholder="$t('general.keyword')" class="wd-200 mr-15 filter-item" @keyup.enter.native="handleFilterRecoltes" />
        <el-button v-waves class="mr-15 filter-item" type="primary" icon="el-icon-search" @click="handleFilterRecoltes">
          {{ $t('general.search') }}
        </el-button>
        <el-button v-waves :loading="downloading" class="filter-item" type="primary" icon="el-icon-download" @click="handleDownload">
          {{ $t('general.export') }}
        </el-button>
        <!-- <el-button v-if="checkPermission(['creer recolte'])" class="mr-15 filter-item" type="primary" icon="el-icon-plus" @click="handlecreateRecolte">
          {{ $t('general.add') }}
        </el-button> -->
      </div>
      <el-table v-loading="loading" :data="list" border fit highlight-current-row style="width: 100%">
        <el-table-column align="center" label="Code" width="80">
          <template slot-scope="scope">
            <span>{{ scope.row.code }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('recolte.date')">
          <template slot-scope="scope">
            <span>{{ scope.row.date_recolte_libelle }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('producteur.producteur')">
          <template slot-scope="scope">
            <span>{{ scope.row.producteur }}</span>
          </template>

        </el-table-column>
        <el-table-column align="center" :label="$t('parcelle.superficie')">
          <template slot-scope="scope">
            <span>{{ scope.row.parcelle.superficie }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('sacs.nombreSacs')">
          <template slot-scope="scope">
            <span>{{ scope.row.nbrs_sac }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('recolte.poidsTotal')">
          <template slot-scope="scope">
            <span>{{ scope.row.poids_total }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('recolte.commentaire')">
          <template slot-scope="scope">
            <span>{{ scope.row.commentaire }}</span>
          </template>
        </el-table-column>

        <!-- <el-table-column align="center" :label="$t('recolte.recolte')">
          <template slot-scope="scope">
            <div class="flex flex-wrap gap-2 my-2">
              <el-tag v-if="!scope.row.recolte_cloturee" type="danger" class="mx-1" effect="light" round>EN COURS</el-tag>
              <el-tag v-if="scope.row.recolte_cloturee" type="success" class="mx-1" effect="light" round>CLÔTURÉE</el-tag>
            </div>
          </template>
        </el-table-column> -->

        <el-table-column align="center" :label="$t('general.actions')">
          <template slot-scope="scope">
            <el-button type="info" size="small" icon="el-icon-view" @click="getRecolteLink(scope.row.id)" />
          </template>
        </el-table-column>
      </el-table>
      <pagination v-if="total > 0" :total="total" :page.sync="query.page" :limit.sync="query.limit" @pagination="getRecoltesList" />
      <!-- ======= REGION USER DIALOG ======= -->
      <el-dialog
        :title="editing ? $t('recolte.edit') + ' ' + currentRecolte.libelle : $t('recolte.AddRecolte')"
        :visible.sync="dialogCreateRecolte"
        :before-close="handleCloseCampagneagricoleCreateDraw"
        :loading="recolteCreating"
        width="30%"
      >
        <div class="content-inputs">
          <el-form ref="recolteForm" :rules="rules" :model="currentRecolte" label-position="left">
            <div class="ligne">
              <el-form-item :label="$t('recolte.recolte')" prop="recolte_id">
                <el-select v-model="currentRecolte.recolte_id" class="filter-item" :placeholder="$t('recolte.recolte')" style="width: 100%;" prop="recolte_id">
                  <el-option
                    v-for="(recolte, idex) in recoltes"
                    :key="idex"
                    :label="recolte.libelle | uppercaseFirst"
                    :value="recolte.id"
                    :disabled="!recolte.is_active"
                  />
                </el-select>
              </el-form-item>
              <el-button class="bouton" type="primary" :icon="dialogCreateRecolte ? 'el-icon-minus' : 'el-icon-plus'" circle @click="dialogCreateRecolte=!dialogCreateRecolte" />
            </div>
            <el-form-item :label="$t('general.libelle')" prop="libelle">
              <el-input v-model="currentRecolte.libelle" />
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <div style="text-align:right;">
            <el-button plain type="danger" @click="dialogCreateRecolte=false">
              {{ $t('permission.cancel') }}
            </el-button>
            <el-button type="primary" :loading="recolteCreating" @click="editing ? updateRecolte() : createRecolte()">
              {{ recolteCreating ? $t('general.saving') : $t('general.save') }}
            </el-button>
          </div>
        </span>
      </el-dialog>

      <el-dialog
        :title="$t('Rechercher un sac')"
        :visible.sync="sacSearch.dialogSearchSac"
        :before-close="handleCloseCampagneagricoleCreateDraw"
        :loading="sacSearch.sacSearching"
        width="30%"
      >
        <div class="content-inputs">
          <el-form ref="sacForm" :rules="rules" :model="sacSearch.keyword" label-position="left">
            <el-form-item :label="$t('Code du sac')" prop="code">
              <el-input v-model="sacSearch.keyword.code" />
            </el-form-item>
            <el-alert
              v-show="sacSearch.sacSearchError"
              :title="sacSearch.sacSearchMessage"
              :closable="false"
              type="error"
              show-icon
            />
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <div style="text-align:right;">
            <el-button plain type="danger" @click="sacSearch.dialogSearchSac=false">
              {{ $t('permission.cancel') }}
            </el-button>
            <el-button type="primary" :loading="sacSearch.sacSearching" @click="getSacDetail()">
              Rechercher
            </el-button>
          </div>
        </span>
      </el-dialog>

      <el-dialog
        :title="$t('Informations sur le sac')"
        :visible.sync="sacSearch.dialogSacInfo"
        :before-close="handleCloseCampagneagricoleCreateDraw"
        :loading="sacSearch.sacSearching"
        width="60%"
      >
        <div v-if="currentSac.code" class="dialog-body">
          <el-col :span="40">
            <el-card shadow="never">
              <el-col :span="6" align="center">
                <div class="text item">
                  <el-avatar shape="square" :size="100" src="https://res.cloudinary.com/dwj6sdjxn/image/upload/v1669370400/fertilizer_2_amfrld.png" />
                </div>
              </el-col>
              <el-col :span="8">
                <el-tag size="large" type="info" effect="plain"><span style="font-weight:bold; font-size:medium">{{ currentSac.code+ ' / ' + currentSac.poids + ' Kg ' }}</span></el-tag>&nbsp;
                <el-tag size="large" type="success"><span style="font-weight:bold"><a @click="getRecolteLink(currentSac.recolte.id)">{{ 'COLLECTE : ' + currentSac.recolte.code }}</a></span></el-tag>
                <el-tag size="large" type="success"><span style="font-weight:bold"><a @click="getParcelleLink(currentSac.recolte.parcelle.id)">{{ 'PARCELLE : ' + currentSac.recolte.parcelle.code }}</a></span></el-tag>
                <el-tag v-if="currentSac.lot" size="large" type="success"><span style="font-weight:bold"><a @click="getLotLink(currentSac.lot.id)">{{ 'LOT : ' + currentSac.lot.code }}</a></span></el-tag>
              </el-col>
              <el-col :span="8">
                <el-tag
                  size="large"
                  type="info"
                  class="mx-1"
                  effect="plain"
                >
                  <span style="font-weight:bold; font-size:medium">PRODUCTEUR</span>
                </el-tag>&nbsp;
                <el-tag size="large" effect="light" type="success"><span style="font-weight:bold"><a @click="getProducteurLink(currentSac.producteur.id)">{{ 'Code : ' + currentSac.producteur.code }}</a></span></el-tag>
                <el-tag size="large" effect="light" type="success"><span style="font-weight:bold"><a @click="getProducteurLink(currentSac.producteur.id)">Nom : {{ currentSac.producteur.nom.toUpperCase() +' '+ currentSac.producteur.prenom.charAt(0).toUpperCase() + currentSac.producteur.prenom.slice(1) }}</a></span></el-tag>
                <el-tag size="large" effect="light" type="success"><span style="font-weight:bold"><a @click="getGroupementLink(currentSac.producteur.groupement_id)">Groupement : {{ currentSac.producteur.groupement.nom.charAt(0).toUpperCase() + currentSac.producteur.groupement.nom.slice(1) }}</a></span></el-tag>
                <el-tag size="large" effect="light" type="success"><span style="font-weight:bold"><a @click="getUniteProductionLink(currentSac.producteur.groupement.unite_production_id)">Unité de production : {{ currentSac.producteur.groupement.unite_production.nom.charAt(0).toUpperCase() + currentSac.producteur.groupement.unite_production.nom.slice(1) }}</a></span></el-tag>
              </el-col>
            </el-card>
          </el-col>
        </div>
        <span slot="footer" class="dialog-footer">
          <div style="text-align:right;">
            <el-button plain type="danger" @click="sacSearch.dialogSacInfo=false">
              {{ $t('permission.cancel') }}
            </el-button>
          </div>
        </span>
      </el-dialog>

      <el-dialog
        :title="$t('recolte.AddRecolte')"
        :visible.sync="dialogCreateRecolte"
        :before-close="handleCloseCampagneagricoleCreateDraw"
        :loading="recolteCreating"
        width="30%"
      >
        <div class="content-inputs">
          <el-form ref="recolteForm" :rules="rules" :model="currentRecolte" label-position="left">
            <el-form-item :label="$t('recolte.libelle')" prop="libelle">
              <el-input v-model="currentRecolte.libelle" />
            </el-form-item>
            <el-form-item :label="$t('general.description')" prop="description">
              <el-input v-model="currentRecolte.description" type="textarea" />
            </el-form-item>
          </el-form>
          <el-radio-group v-model="ci" size="medium">
            <el-radio-button :label="'audit'">Audit</el-radio-button>
            <el-radio-button :label="'ci'">Contrôle interne</el-radio-button>
          </el-radio-group>
        </div>
        <span slot="footer" class="dialog-footer">
          <div style="text-align:right;">
            <el-button plain type="danger" @click="dialogCreateRecolte=false">
              {{ $t('permission.cancel') }}
            </el-button>
            <el-button type="primary" :loading="recolteCreating" @click="editing ? updateRecolte() : createRecolte()">
              {{ recolteCreating ? $t('general.saving') : $t('general.save') }}
            </el-button>
          </div>
        </span>
      </el-dialog>

      <!-- ======= REGION USER DIALOG ======= -->

    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
import moment from 'moment';
// const recolteResource = new Resource('recoltes');
const recolteResource = new Resource('recoltes');
const recolteWebResource = new Resource('recoltes-web');
const campagneagricoleResource = new Resource('campagne_agricoles');

const sacResource = new Resource('sacs/info');
export default {
  name: 'RecoltesList',
  components: { Pagination },
  directives: { waves, permission, role },
  data() {
    return {
      recoltes: [],
      libelleRecolte: [],
      ci: '',
      list: null,
      total: 0,
      loading: true,
      downloading: false,
      recolteCreating: false,
      campagneFilter: null,
      campagnes: null,
      // recolteCreating: false,
      currentRecolte: {},
      sacSearch: {
        keyword: { code: '' },
        sacSearching: false,
        dialogSacInfo: false,
        dialogSearchSac: false,
        sacSearchError: false,
        sacSearchMessage: '',
      },
      currentSac: {},
      editing: false,
      // currentRecolte: {},
      query: {
        page: 1,
        limit: 15,
        keyword: '',
      },
      queryfiltre: {
        page: 1,
        limit: 15,
        keyword: '',
      },
      dialogCreateRecolte: false,
      // dialogCreateRecolte: false,
      currentRecolteId: 0,
      rules: {
        libelle: [{ required: true, message: this.$t('recolte.LibelleRequired'), trigger: 'blur' }],
        code: [{ required: true, message: this.$t('code Required'), trigger: 'blur' }],
        recolte_id: [{ required: true, message: this.$t('recolte.RecolteRequired'), trigger: 'blur' }],
      },
      fileList: [],
    };
  },
  computed: {

  },
  created() {
    this.resetNewRecolte();
    this.resetSearchSac();
    this.getRecoltesList();
    this.getCategoriesRecoltesList();
    this.getCampagnesList();
  },
  methods: {
    checkPermission,
    checkRole,
    isEmptyArray(array){
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    handleCloseCampagneagricoleCreateDraw(done) {
      this.$confirm(this.$t('general.closingWarningText'))
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    cancelrecolteForm() {
      this.$refs.CampagneagricoleCreationDrawer.closeDrawer();
    },
    setRecolteStatus(id, statut){

    },
    async filterByCampagne(value){
      this.query.campagne_agricole_id = this.campagnes[value].id;
      this.getRecoltesList();
    },
    async getCampagnesList() {
      const { data } = await campagneagricoleResource.list(this.queryfiltre);
      this.campagnes = data;
    },
    initSearch(){
      this.handleFilterRecoltes();
    },
    async getRecoltesList() {
      this.loading = true;
      const { data, total } = await recolteWebResource.list(this.query);
      this.list = data.reverse();
      this.total = total;
      this.loading = false;
    },
    async getSubCampagneagricolesList(subUrl) {
      this.loading = true;
      const { data } = await recolteResource.sublist('/' + subUrl, this.query);
      this.list = data;
      this.loading = false;
    },
    handleFilterRecoltes() {
      this.query.page = 1;
      this.getRecoltesList();
    },
    handlecreateRecolte() {
      this.editing = false;
      this.resetNewRecolte();
      this.dialogCreateRecolte = true;
      this.$nextTick(() => {
        this.$refs['recolteForm'].clearValidate();
      });
    },
    handleSearchSac() {
      this.editing = false;
      this.resetSearchSac();
      this.sacSearch.dialogSearchSac = true;
    },
    async getSacDetail() {
      // this.loading = true;
      if (!this.sacSearch.keyword.code) {
        this.sacSearch.sacSearchMessage = 'Veuillez saisir un code';
        this.sacSearch.sacSearchError = true;
      } else {
        const data = await sacResource.getSacInfo(this.sacSearch.keyword);
        if (!data.success) {
          this.sacSearch.sacSearchMessage = data.message;
          this.sacSearch.sacSearchError = true;
        } else {
          this.currentSac = data.data;
          this.sacSearch.dialogSearchSac = false;
          this.sacSearch.dialogSacInfo = true;
          this.sacSearch.sacSearchError = false;
        }
      }
    },
    getRecolteLink(id){
      console.log('recolteId : ', id);
      this.$router.push({ path: '/recoltes/' + id });
    },
    getProducteurLink(id){
      console.log('producteurId : ', id);
      this.$router.push({ path: '/producteurs/' + id });
    },
    getParcelleLink(id){
      console.log('parcelleId : ', id);
      this.$router.push({ path: '/parcelles/' + id });
    },
    getLotLink(id){
      this.$router.push({ path: '/lots/' + id });
    },
    getGroupementLink(id){
      console.log('group : ', id);
      this.$router.push({ path: '/groupement/' + id });
    },
    getUniteProductionLink(id){
      console.log('unite pro : ', id);
      this.$router.push({ path: '/uniteProduction/' + id });
    },

    handleEditRecolte(id){
      this.editing = true;
      this.resetNewRecolte();
      this.currentRecolte = this.list.find(abonne => abonne.id === id);
      this.dialogCreateRecolte = true;
      this.$nextTick(() => {
        this.$refs['recolteForm'].clearValidate();
      });
    },
    handleDeleteRecolte(id, visible, libelle) {
      console.log('id : ' + id + 'libelle : ' + libelle);
      this.$confirm(this.$t('recolte.deleteWarn') + ' ' + libelle + '. ' + this.$t('general.deleteContinue'), 'Warning', {
        confirmButtonText: 'Oui',
        cancelButtonText: this.$t('general.cancel'),
        type: 'warning',
      }).then(() => {
        console.log('result : ');
        if (!visible && (checkRole(['admin']) || checkPermission(['supprimer collecte']))) {
          console.log('result 2 : ');
          this.$swal({
            title: this.$t('general.SureRecolte'),
            text: this.$t('general.irrversibleMessage'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$t('general.ouiSupprimer'),
          }).then((result) => {
            if (result.isConfirmed) {
              recolteResource.destroy(id).then(response => {
                this.$message({
                  type: 'success',
                  message: this.$t('recolte.suppressionEffectue'),
                });
                this.initSearch();
              }).catch(error => {
                console.log(error);
              });
            }
          });
        } else {
          this.setRecolteStatus(id, 'visible');
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('general.suppressionAnnule'),
        });
      });
    },
    createRecolte() {
      this.$refs['recolteForm'].validate((valid) => {
        if (valid) {
          this.recolteCreating = true;
          recolteResource
            .store(this.currentRecolte)
            .then(async(response) => {
              this.$message({
                message: this.$t('recolte.NewRecolte') + ' ' + this.currentRecolte.libelle + ' ' + this.$t('recolte.iscreatedSuccessfully'),
                type: 'success',
                duration: 5 * 1000,
              });
              this.currentRecolteId = response.data.id;
              this.resetNewRecolte();
              this.dialogCreateRecolte = false;
              this.handleFilterRecoltes();
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.recolteCreating = false;
            });
        } else {
          console.log('Erreur !!');
          return false;
        }
      });
    },
    resetNewRecolte() {
      this.currentRecolte = {
        libelle: '',
        description: '',
      };
    },
    resetSearchSac() {
      this.sacSearch.keyword = {
        code: '',
      };
    },
    // createRecolte() {
    //   this.$refs['recolteForm'].validate((valid) => {
    //     if (valid && this.ci.length > 0) {
    //       this.recolteCreating = true;
    //       this.currentRecolte.ci = this.ci !== 'audit';
    //       recolteResource
    //         .store(this.currentRecolte)
    //         .then(async(response) => {
    //           this.$message({
    //             message: this.$t('recolte.NewRecolte') + ' ' + this.currentRecolte.libelle + ' ' + this.$t('recolte.iscreatedSuccessfully'),
    //             type: 'success',
    //             duration: 5 * 1000,
    //           });
    //           console.log('LA NOULEEE : ', response.data.id);
    //           this.currentRecolte.recolte_id = response.data.id;
    //           this.resetNewRecolte();
    //           this.dialogCreateRecolte = false;
    //           this.handleFilterRecoltes();
    //         })
    //         .catch(error => {
    //           console.log(error);
    //         })
    //         .finally(() => {
    //           this.recolteCreating = false;
    //           this.dialogCreateRecolte = false;
    //           this.getCategoriesRecoltesList();
    //         });
    //     } else {
    //       this.$message({
    //         type: 'info',
    //         message: this.$t('general.formInvalid'),
    //       });
    //       return false;
    //     }
    //   });
    // },
    updateRecolte() {
      this.$refs['recolteForm'].validate((valid) => {
        if (valid) {
          this.recolteCreating = true;
          recolteResource.update(this.currentRecolte.id, this.currentRecolte).then(response => {
            this.resetNewRecolte();
            this.handleFilterRecoltes();
            this.$message({
              message: this.$t('recolte.isupdatedSuccessfully'),
              type: 'success',
              duration: 5 * 1000,
            });
            this.dialogCreateRecolte = false;
            this.editing = false;
          })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.recolteCreating = false;
            });
        } else {
          console.log('Erreur !!');
          return false;
        }
      });
    },
    // resetNewRecolte() {
    //   this.currentRecolte = {
    //     libelle: '',
    //     recolte_id: null,
    //   };
    // },
    handleDownload() {
      this.downloading = true;
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['ID', this.$t('general.code'), this.$t('recolte.libelle')];
        const filterVal = ['id', 'code', 'libelle'];
        const data = this.formatJson(filterVal, this.list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'Liste des recoltes SCIAGRI_' + moment().format('DD-MM-YYYY_hh-mm'),
        });
        this.downloading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    onChange(file, fileList){
      var a = this.fileList;
      a.push({ name: file.name, url: file.url });
      // console.log('onChange begin');
      this.fileList = a;
      // console.log(this.fileList);
      // console.log('onChange end');
    },
    async getCategoriesRecoltesList() {
      const { data } = await recolteWebResource.list(this.query);
      this.recoltes = data;
      const _reg = [];
      data.forEach(recolte => {
        _reg[recolte.id] = recolte.libelle;
      });
      this.libelleRecolte = _reg;
    },
  },
};
</script>

<style lang="scss" scoped>
.ligne {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.bouton {
  height: 40px;
  width: 40px;
  margin-right: auto;
  margin-left: 15px;
  margin-top: 10px;
}
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}
</style>
